<template>
  <div>
    <!-- 面包屑 -->
    <div class="bread-box">
      <ul class="container">
        <li class="breadcrumb-item">
          <p>
            当前位置：<a href="/">首页</a><span>>></span
            ><a href="/production">药品信息(收费)</a>
          </p>
        </li>
      </ul>
    </div>
    <!-- 药品信息数据 -->
    <div>
      <div class="prod-detail">
        <h6 class="prod-title">{{ info.cDrugName }}</h6>
        <p class="prod-hit">点击：{{ info.count }}次</p>
        <div class="prod-drug-box">
          <p>
            <img :src="info.img" :alt="info.cDrugName" />
          </p>
        </div>
      </div>
      <div class="prod-extra">
        <div class="prod-mask"></div>
        <a-button type="" class="read-more-btn" @click="modalShow"
          >5元/篇阅读全文</a-button
        >
      </div>
    </div>
    <!-- 上一篇下一篇 -->

    <div class="prenext">
      <ul class="container">
        <li v-for="(item, i) in prenextList" :key="i" class="prenext-li">
          <span class="prenext-label">{{ item.label }}：</span
          ><span v-if="item.isNone" class="prenext-span">{{
            item.cDrugName
          }}</span>
          <a
            class="prenext-link"
            :href="`/production/detail?id=${item.id}`"
            v-else
            >{{ item.cDrugName }}</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
/****************
 *@author: niubj
 *@date: 2021-11-25 14:37:01
 *@version: V1.0.0
 *@description: 药品详情页面
 *************************************************************************/
import { Modal } from "ant-design-vue";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      productionList: [
        {
          id: 150,
          img: require("../assets/images/EOG001007C.png"),
          name: "利动",
          cDrugName: "乳果糖口服溶液",
          count: 20,
        },
        {
          id: 160,
          img: require("../assets/images/EFB002030C.png"),
          name: "爱普芬",
          cDrugName: "布洛芬混悬液",
          count: 40,
        },
        {
          id: 151,
          img: require("../assets/images/ENC002003C.png"),
          name: "易坦静",
          cDrugName: "氨溴特罗口服溶液",
          count: 45,
        },
        {
          id: 152,
          img: require("../assets/images/BOA012001C.png"),
          name: "益动",
          cDrugName: "马来酸多潘立酮片",
          count: 120,
        },
        {
          id: 153,
          img: require("../assets/images/HQS016011G.png"),
          name: "润口腔炎喷雾剂",
          cDrugName: "口腔炎喷雾剂",
          count: 26,
        },
        {
          id: 155,
          img: require("../assets/images/HQB002010C.png"),
          name: "采特",
          cDrugName: "盐酸特比萘芬喷雾剂",
          count: 44,
        },
        {
          id: 156,
          img: require("../assets/images/HGF005001C.png"),
          name: "锐迈",
          cDrugName: "酮洛芬凝胶",
          count: 13,
        },
        {
          id: 158,
          img: require("../assets/images/DAB007021D.png"),
          name: "润头孢克洛颗粒",
          cDrugName: "头孢克洛颗粒",
          count: 77,
        },
        {
          id: 157,
          img: require("../assets/images/ILELEYSJ20P.png"),
          name: "iLELE益生菌固体饮料",
          cDrugName: "iLELE益生菌固体饮料",
          count: 56,
        },
        {
          id: 159,
          img: require("../assets/images/DOE001070C.png"),
          name: "美常灵",
          cDrugName: "蒙脱石散",
          count: 66,
        },
        {
          id: 161,
          img: require("../assets/images/EFA008020C.png"),
          name: "彤朗",
          cDrugName: "对乙酰氨基酚口服溶液",
          count: 96,
        },
        {
          id: 162,
          img: require("../assets/images/EWN020001C.png"),
          name: "宝丽亚",
          cDrugName: "吸入用丙酸倍氯米松混悬液",
          count: 12,
        },

        {
          id: 163,
          img: require("../assets/images/EWN027001C.png"),
          name: "易安平",
          cDrugName: "吸入用盐酸氨溴索溶液",
          count: 23,
        },
        {
          id: 164,
          img: require("../assets/images/BUC007007F.png"),
          name: "美常安",
          cDrugName: "枯草杆菌二联活菌肠溶胶囊",
          count: 25,
        },
        {
          id: 154,
          img: require("../assets/images/ENA005155C.png"),
          name: "易坦静berry",
          cDrugName: "盐酸氨溴索口服溶液",
          count: 46,
        },
        {
          id: 78,
          img: require("../assets/images/DUC001007F.png"),
          name: "妈咪爱",
          cDrugName: "枯草杆菌二联活菌颗粒",
          count: 53,
        },
        {
          id: 149,
          img: require("../assets/images/kfysby.png"),
          name: "润口服补液盐散(Ⅲ)",
          cDrugName: "口服补液盐散(Ⅲ)",
          count: 8,
        },
        {
          id: 200,
          img: require("../assets/images/tdx123.jpg"),
          name: "泰得欣",
          cDrugName: "注射用头孢他啶",
          count: 19,
        },
        {
          id: 78,
          img: require("../assets/images/DUC001007F.png"),
          name: "妈咪爱",
          cDrugName: "枯草杆菌二联活菌颗粒",
          count: 20,
        },
        {
          id: 79,
          img: require("../assets/images/ENC002002C.png"),
          name: "易坦静",
          cDrugName: "氨溴特罗口服溶液",
          count: 28,
        },
        {
          id: 80,
          img: require("../assets/images/kcgjmca.jpg"),
          name: "美常安",
          cDrugName: "枯草杆菌二联活菌肠溶胶囊",
          count: 73,
        },
        {
          id: 81,
          img: require("../assets/images/DAB013068D.png"),
          name: "润头孢克肟干混悬剂",
          cDrugName: "头孢克肟干混悬剂",
          count: 8,
        },
        {
          id: 82,
          img: require("../assets/images/HQS016025G.png"),
          name: "润口腔炎喷雾剂",
          cDrugName: "口腔炎喷雾剂",
          count: 26,
        },
        {
          id: 83,
          img: require("../assets/images/EHA002006C.png"),
          name: "仙达安",
          cDrugName: "盐酸西替利嗪口服溶液",
          count: 59,
        },

        {
          id: 84,
          img: require("../assets/images/EHA004025C.png"),
          name: "仙达乐",
          cDrugName: "盐酸左西替利嗪口服溶液",
          count: 33,
        },
        {
          id: 85,
          img: require("../assets/images/EOG001009C.png"),
          name: "利动",
          cDrugName: "乳果糖口服溶液",
          count: 23,
        },
      ],
      id: this.$route.query.id,
      info: {},
      prenextList: [],
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.computedCurrent();
  },
  methods: {
    // 计算当前数据
    computedCurrent() {
      let lengthProd = this.productionList.length;
      let list = this.productionList;
      for (let i = 0; i < lengthProd; i++) {
        const item = this.productionList[i];
        if (item.id == this.id) {
          this.info = item;
          if (i == 0) {
            this.prenextList = [
              {
                label: "上一篇",
                cDrugName: "没有了",
                isNone: true,
              },
              {
                label: "下一篇",
                cDrugName: list[i + 1].cDrugName,
                id: list[i + 1].id,
                isNone: false,
              },
            ];
          } else if (i == lengthProd - 1) {
            this.prenextList = [
              {
                label: "上一篇",
                cDrugName: list[i - 1].cDrugName,
                id: list[i - 1].id,
                isNone: false,
              },
              {
                label: "下一篇",
                cDrugName: "没有了",
                isNone: true,
              },
            ];
          } else {
            this.prenextList = [
              {
                label: "上一篇",
                cDrugName: list[i - 1].cDrugName,
                id: list[i - 1].id,
                isNone: false,
              },
              {
                label: "下一篇",
                cDrugName: list[i + 1].cDrugName,
                id: list[i + 1].id,
                isNone: false,
              },
            ];
          }
        }
      }
    },
    modalShow() {
      Modal.confirm({
        title: "会员阅读需付费5元",
        content: "",
        okText: "前往付费",
        cancelText: "取消",
        width: "300px",
        centered: true,
        closable: true,
        onOk: () => {
          this.$router.push({
            path: "/register",
          });
        },
        onCancel() {},
        class: "user-define-modal",
      });
    },
  },
};
</script>
<style lang="less" scoped>
.bread-box {
  background: #f5f5f5;
  padding: 10px 0px;
}
.breadcrumb-item {
  p {
    display: inline-block;
    padding: 5px 10px;
    text-align: center;
    margin-right: 35px;
    font-size: 13px;
    border-radius: 15px;
    background-color: #1890ff;
    color: #fff;
    a {
      color: #fff;
    }
    span {
      padding: 0 4px;
    }
  }
}
.prenext {
  ul {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .prenext-li {
    margin: 40px 30px 40px 0;
    padding-left: 40px;
    color: #666;
    font-size: 16px;
  }
}
.prod-detail {
  padding: 35px 0;
}
.prod-title {
  font-size: 18px;
  text-align: center;
}
.prod-hit {
  padding: 10px 0px;
  font-size: 13px;
  color: #999;
  text-align: center;
  border-bottom: 1px dashed #d8d8d8;
}
.prod-drug-box {
  padding-top: 25px;
  height: 320px;
  line-height: 2.1;
  font-size: 14px;
  color: #666;
  overflow: hidden;
  p {
    text-align: center;
    img {
      top: -40px;
      position: relative;
    }
  }
}
.prod-extra {
  position: relative;
  z-index: 2;
  padding: 0 0 25px;
  width: 800px;
  margin: -230px auto 0;
  text-align: center;
  .prod-mask {
    height: 240px;
    background: linear-gradient(rgba(255, 255, 255, 0.1) 0%, #fff 100%);
    background: -webkit-gradient(rgba(255, 255, 255, 0.1) 0%, #fff 100%);
    background: -o-linear-gradient(rgba(255, 255, 255, 0.1) 0%, #fff 100%);
  }
  .read-more-btn {
    font-size: 16px;
    color: #e60013;
    background: #fff;
    border-radius: 4px;
    border: 1px solid #e60013;
    height: 40px;
  }
}
</style>
